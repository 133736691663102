//prices array
//column 1 = main price no side
//column 2 = with 1 side
//column 3 = with 2 side
//column 4 = with 3 side

const items = [
    {
        id: 1,
        category: 'Mains',
        title: 'Peri Peri Single Chicken Quarter Leg',
        addon: ['1 Side','2 Sides','na','na'],
        prices: ['13.99', '15.99', 'na', 'na'],
        description: 'Peri Peri Single Chicken Quarter Leg'
    },
    {
        id: 2,
        category: 'Mains',
        title: 'Peri Peri Double Chicken Quarter Legs',
        addon: ['1 Side', '2 Sides', 'na', 'na'],
        prices: ['17.99', '19.99', 'na', 'na'],
        description: 'Peri Peri Double Chicken Quarter Legs'
    },
    {
        id: 3,
        category: 'Mains',
        title: 'Peri Peri Half Chicken',
        addon: ['1 Side', '2 Sides', 'na', 'na'],
        prices: ['18.99', '20.99', 'na', 'na'],
        description: 'Peri Peri Half Chicken'
    },
    {
        id: 4,
        category: 'Mains',
        title: 'Peri Peri Whole Chicken',
        addon: ['1 Side', '2 Sides', 'na', 'na'],
        prices: ['28.99', '30.99', 'na', 'na'],
        description: 'Peri Peri Whole Chicken'
    },
    {
        id: 5,
        category: 'Mains',
        title: 'BBQ Half Back Ribs',
        addon: ['1 Side', '2 Sides', 'na', 'na'],
        prices: ['16.99', '20.99', 'na', 'na'],
        description: 'BBQ Half Back Ribs'
    },
    {
        id: 101,
        category: 'Combos',
        title: '1 Chicken Quarter Legs with Half Back Ribs',
        addon: ['2 Sides', '3 Sides', 'na', 'na'],
        prices: ['26.99', '28.99', 'na', 'na'],
        description: '1 Chicken Quarter Legs with Half Back Ribs'
    },
    {
        id: 102,
        category: 'Combos',
        title: '2 Quarter Legs with Full Back Ribs',
        addon: ['2 Sides', '3 Sides', 'na', 'na'],
        prices: ['43.99', '48.99', 'na', 'na'],
        description: '2 Quarter Legs with Full Back Ribs'
    },
    {
        id: 103,
        category: 'Combos',
        title: '1 Whole Chicken with Full Back Ribs',
        addon: ['2 Sides', '3 Sides', 'na', 'na'],
        prices: ['55.99', '59.99', 'na', 'na'],
        description: '1 Whole Chicken with Full Back Ribs'
    },
    {
        id: 201,
        category: 'Sharables',
        title: 'Whole Chicken Wings',
        addon: ['6 pcs', '12 pcs', 'na', 'na'],
        prices: ['13.99', '25.99', 'na', 'na'],
        description: 'Whole Chicken Wings'
    },
    {
        id: 202,
        category: 'Sharables',
        title: 'Fettuccine Alfredo',
        addon: ['na', 'Add Shrimp', 'Add Chicken', 'na'],
        prices: ['16.99', '6.99', '6.99', 'na'],
        description: 'Fettuccine Alfredo'
    },
    {
        id: 203,
        category: 'Sharables',
        title: 'Penne Bolognese',
        addon: ['na', 'na', 'na', 'na'],
        prices: ['16.99', 'na', 'na', 'na'],
        description: 'Penne Bolognese'
    },
    {
        id: 301,
        category: 'Kids Meal',
        title: 'Chicken Fingers with Fries',
        addon: ['na', 'na', 'na', 'na'],
        prices: ['7.99', 'na', 'na', 'na'],
        description: 'Chicken Fingers with Fries'
    },
    {
        id: 302,
        category: 'Kids Meal',
        title: 'Mac and Cheese with Fries',
        addon: ['na', 'na', 'na', 'na'],
        prices: ['7.99', 'na', 'na', 'na'],
        description: 'Mac and Cheese with Fries'
    },
    {
        id: 351,
        category: 'Refreshments',
        title: 'Fruit Juice',
        addon: ['na', 'na', 'na', 'na'],
        prices: ['3.50', 'na', 'na', 'na'],
        description: 'Fruit Juice'
    },
    {
        id: 352,
        category: 'Refreshments',
        title: 'Soda in Can',
        addon: ['na', 'na', 'na', 'na'],
        prices: ['2.50', 'na', 'na', 'na'],
        description: 'Soda in Can'
    },
    {
        id: 353,
        category: 'Refreshments',
        title: 'Iced Tea',
        addon: ['na', 'na', 'na', 'na'],
        prices: ['2.50', 'na', 'na', 'na'],
        description: 'Iced Tea'
    },
    {
        id: 401,
        category: 'Sides',
        title: 'Potato Salad',
        addon: ['na', 'na', 'na', 'na'],
        prices: ['6.99', 'na', 'na', 'na'],
        description: 'Potato Salad'
    },
    {
        id: 402,
        category: 'Sides',
        title: 'Macaroni Salad',
        addon: ['na', 'na', 'na', 'na'],
        prices: ['6.99', 'na', 'na', 'na'],
        description: 'Macaroni Salad'
    },
    {
        id: 403,
        category: 'Sides',
        title: 'Peri Peri Fries',
        addon: ['na', 'na', 'na', 'na'],
        prices: ['4.99', 'na', 'na', 'na'],
        description: 'Peri Peri Fries'
    },
    {
        id: 404,
        category: 'Sides',
        title: 'Coleslaw',
        addon: ['na', 'na', 'na', 'na'],
        prices: ['4.99', 'na', 'na', 'na'],
        description: 'Coleslaw'
    },
    {
        id: 405,
        category: 'Sides',
        title: 'Java Rice',
        addon: ['na', 'na', 'na', 'na'],
        prices: ['4.99', 'na', 'na', 'na'],
        description: 'Java Rice'
    },
    {
        id: 406,
        category: 'Sides',
        title: 'Mac and Cheese',
        addon: ['na', 'na', 'na', 'na'],
        prices: ['6.99', 'na', 'na', 'na'],
        description: 'Mac and Cheese'
    },
    {
        id: 407,
        category: 'Sides',
        title: 'Ceasar Salad',
        addon: ['na', 'na', 'na', 'na'],
        prices: ['6.99', 'na', 'na', 'na'],
        description: 'Ceasar Salad'
    },
    {
        id: 408,
        category: 'Sides',
        title: 'Buttered Corn',
        addon: ['na', 'na', 'na', 'na'],
        prices: ['6.99', 'na', 'na', 'na'],
        description: 'Buttered Corn'
    }
    //{
    //    id: 1,
    //    category: '',
    //    title: '',
    //    prices: ['', '', ''],
    //    description: ''
    //},
    
]

export default items