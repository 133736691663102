import React from 'react';
import './Franchise.css';
import Footer from '../Footer';

const Franchise = () => {
    return (
        <div id='Franchise'>
            <h7>Franchise</h7>
            <div className='FranchiseContent'>                
                <p>For franchise inquiries or opportunities, <br/> please call 204-774-3795 or email peripointcnc@gmail.com</p>
            </div>
            <Footer />   
        </div>
    );
};

export default Franchise;