import React from 'react';
import './Sidebar.css';

const Sidebar = ({ isOpen, toggle }) => {
    return (
        <div className='SidebarContainer' style={{ 'width': `${isOpen ? '75vw' : '0vw'}`, 'display': `${isOpen ? 'grid' : 'none'}` }}>
            <div className='SidebarItems'>
                <a onClick={toggle}  href='#Menu'>
                    <div className='SidebarLink'>Menu</div>
                </a>
                <a onClick={toggle}  href='#Locations'>
                    <div className='SidebarLink'>Locations</div>
                </a>
                <a onClick={toggle}  href='#Promo'>
                    <div className='SidebarLink'>Promotions</div>
                </a>
                <a onClick={toggle}  href='#Franchise'>
                    <div className='SidebarLink'>Franchise</div>
                </a>
                <a onClick={toggle} href='#OrderNowLinks'>
                    <div className='SidebarLink'>Order Now</div>
                </a>
            </div>            
        </div> 
    );
};

export default Sidebar;