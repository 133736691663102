import React from 'react';
import './Locations.css';

const Locations = () => {
    return (
        <div id='Locations'>
            <h3>Locations</h3>
            <div className='LocationsItems'>                
                <div className="LocationSection">
                    <div className='PerLocationContainer'>
                        <h4>Winnipeg</h4>
                        <p>946 Sherbrook Street, Winnipeg, Manitoba R3A 1N4</p>
                        <p>(204) 774-3795</p>

                        <a href='https://maps.app.goo.gl/ps2WDpDYyUKnVUNi6'><button className='GetDirection'>GET DIRECTIONS</button></a>

                        <img alt='Winnipeg Location' src={require('../../images/WpgLocation.png')} />
                    </div>
                    <div className='PerLocationContainer'>
                        <h4>Edmonton</h4>
                        <p>Are you ready for Peri Point Chicken and Charcoal Edmonton?!</p>
                        <p>Coming Soon</p>
                        <img alt='Coming Soon' src={require('../../images/ComingSoonLocation.png')}/>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Locations;