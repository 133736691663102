import React from 'react';
import './PromoSection.css';

const PromoSection = () => {
    return (
        <div id='Promo'>
            <h2>Special Discounts</h2>
            <div className='PromoContent'>
                <div className='PromoItems'>                    
                    <ul>
                        <li className='PromoLi'>10% Discount: Available Monday to Friday, 11 AM - 3 PM.</li>
                        <li className='PromoLi'>Additional 5% Discount: Exclusively for Healthcare Workers, available anytime.</li>   
                    </ul>
                    <p className='NoteP'>Note: These offers are valid only at our 946 Sherbrook Street location.</p>
                </div>
            </div>
        </div>
    );
};

export default PromoSection;
