import React, { useState } from 'react';
import './Menu.css';
import items from "./data";
import MenuItems from "./MenuItems";

const Menu = () => {
    const [menuItems, setMenuItems] = useState(items);

    return (
        <div id='Menu'>
            <h5>Menu</h5>
            <div className='MenuContent'>   
                <MenuItems menuItem={menuItems} category={'Mains'} />
                <MenuItems menuItem={menuItems} category={'Combos'} />
                <MenuItems menuItem={menuItems} category={'Sharables'} />
                <MenuItems menuItem={menuItems} category={'Sides'} />
                <MenuItems menuItem={menuItems} category={'Kids Meal'} />
                <MenuItems menuItem={menuItems} category={'Refreshments'} />
            </div>
        </div>
    );
};

export default Menu;