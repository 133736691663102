import React from 'react';
import './Menu.css';

const MenuItems = ({ menuItem, category }) => {    
    const menuByCategory = menuItem.filter(item => item.category === category);    
    
    function CheckArray({ array1, array2 }) {
        let prices = '';
        for (let i in array1) {
            if (array1[i] !== 'na' && array2[i] !== 'na') {
                prices = (prices === '' ? (array1[i] + ' ' + array2[i]) : (prices + ' | ' + array1[i] + ' ' + array2[i]));
            } else if (array1[i] !== 'na' && array2[i] === 'na') {
                prices = (prices === '' ? array1[i] : (prices + ',  ' + array1[i]));
            } else if (array1[i] === 'na' && array2[i] !== 'na') {
                prices = (prices === '' ? array2[i] : (prices + ',  ' + array2[i]));
            }
            i++;             
        } 
        return (
            <p className='menuPrice'>{prices}</p>
        );           
    };    

    return (
        <>
            <h6>{category}</h6>
            <ul className='MenuList'>         
                {menuByCategory.map((item) => {
                    const { id, title, addon, prices } = item;

                    return (
                        <>
                            <li key={id} className='menuTitle'>{title}</li>                        
                            <CheckArray array1={addon} array2={prices} />
                        </>
                    );
                })}
            </ul>
        </>
    );
};

export default MenuItems;