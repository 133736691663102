import React, { useState } from "react";
import './BackToTopButton.css';

const BackToTopButton = () => {
    const [visible, setVisibility] = useState(false);

    const toggleVisibility = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 800) {
            setVisibility(true);
        } else if (scrolled <= 800) {
            setVisibility(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    window.addEventListener("scroll", toggleVisibility);

    return (
        <button className='buttonToTop' onClick={scrollToTop} style={{ display: visible ? "inline" : "none" }}>Back to Top</button>
    );
};

export default BackToTopButton;
