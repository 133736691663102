import React from 'react';
import './SidebarIcon.css';

const SidebarIcon = ({ toggle }) => {
    return (
        <div className='SidebarIcon' onClick={toggle}>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};

export default SidebarIcon;
