import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <div id='FooterContainer'>
            <div className='FooterItems'>                
                <div id='OrderNowLinks'>
                    <p className='footerP'>Order Now</p>
                    <a href="https://www.skipthedishes.com/peri-point-chicken-and-charcoal-sherbrook">
                        <img className="skiplogo" alt="Skip the Dishes Logo" src={require('../../images/Skip_Logo.png')} />
                    </a>
                    <a href="https://www.doordash.com/store/peri-point-chicken-and-charcoal-winnipeg-29487617">
                        <img className="ddlogo" alt="Door Dash Logo" src={require('../../images/DD_Logo.png')} />
                    </a>
                    <a href="https://www.ubereats.com/ca/store/peri-point-chicken-and-charcoal/7yc1OjmzVACKqcydLgoLqg?">
                        <img className="uelogo" alt="Uber Eats Logo" src={require('../../images/UE_Logo.png')} />
                    </a>
                </div>
                <div className='socialMediaLinks'>
                    <p className='footerP'>Visit us on</p>
                    <a href="https://www.facebook.com/people/Peri-Point-Chicken-and-Charcoal/61556976715482/">
                        <img className="fblogo" alt="PeriPointFacebook" src={require('../../images/FB_Logo.png')} />
                    </a>
                    <a href="https://www.instagram.com/peripointwpg/">
                        <img className="iglogo" alt="PeriPointInstagram" src={require('../../images/IG_Logo.png')} />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Footer;