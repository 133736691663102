import React, { useState } from 'react';
import BackToTopButton from './components/BackToTopButton';
import Navbar from './components/Navbar';
import WelcomePage from './components/WelcomePage';
import PromoSection from './components/PromoSection';
import Locations from './components/Locations';
import Menu from './components/Menu';
import Franchise from './components/Franchise';

function App() {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <BackToTopButton />
            <Navbar isOpen={isOpen} toggle={toggle} />
            <WelcomePage />    
            <PromoSection />
            <Locations />
            <Menu />
            <Franchise />
        </>
    );
}


export default App;