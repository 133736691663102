import React from 'react';
import './WelcomePage.css';

const WelcomePage = () => {

    return (
        <div id='WelcomeContainer'>            
            <div className='WelcomeContent'>
                <div className='WelcomeItems'>
                    <h1 className='Heroh1'>PERI POINT CHICKEN AND CHARCOAL</h1>
                    <p className='Herop'>Grilled to Perfection!</p>                
                </div>                
            </div>
        </div>
    );
};

export default WelcomePage;