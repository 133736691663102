import React from 'react';
import './Navbar.css';
import Sidebar from '../Sidebar';
import SidebarIcon from '../SidebarIcon';

const Navbar = ({ isOpen, toggle }) => {
    return (
        <div className='NavbarContainer'>
            <SidebarIcon toggle={toggle} />
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <div className='NavbarMenu'>
                <div className='NavLeft'>
                    <div className='NavLink'>
                        <a href='#Menu'>
                            <div className='navButtons'>Menu</div>
                        </a>
                    </div>
                    <div className='NavLink'>
                        <a href='#Locations'>
                            <div className='navButtons'>Locations</div>
                        </a>
                    </div>
                </div>
                <img className='NavImg' alt='PeriPoint Logo' src={require('../../images/PeriPoint2.png')} />
                
                <div className='NavRight'>
                    <div className='NavLink'>
                        <a href='#Promo'>
                            <div className='navButtons'>Promotions</div>
                        </a>
                    </div>
                    <div className='NavLink'>
                        <a href='#Franchise'>
                            <div className='navButtons' >Franchise</div>
                        </a>
                    </div>
                    
                </div>
                <button className='NavOrderNow'>
                    <a href='#OrderNowLinks'>
                        <div className='navOrderNowButton'>Order Now</div>
                    </a>
                </button>
            </div>              
        </div>
    );
};

export default Navbar;